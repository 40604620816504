import * as actionType from "../actions/actionType";

const initialState = {
  name: "",
  tableList: [],
  loading: false,
  pageNum: 1,
  pageSize: 20,
  tableListTotal: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case actionType.USER_UPDATE_STATE:
    //   return {
    //     ...state,
    //     ...action.payload.obj,
    //   };
    default:
      return state;
  }
};
