// @ts-nocheck
import React from "react";
import { createStore, applyMiddleware, compose } from "redux";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import Loadable from "react-loadable";
import zhCN from "antd/es/locale/zh_CN";
import reduxThunk from "redux-thunk";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import reducer from "./reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(reduxThunk))
);

const loadableProps = {
  timeout: 60 * 1000,
};
const Loading = () => null;

const Index = Loadable({
  ...loadableProps,
  loader: () => import(/* webpackChunkName: "Index" */ "@/pages/Index/Index"),
  loading: Loading,
});

const SampleCenter = Loadable({
  ...loadableProps,
  loader: () =>
    import(
      /* webpackChunkName: "SampleCenter" */ "@/pages/SampleCenter/SampleCenter"
    ),
  loading: Loading,
});

const Services = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "Services" */ "@/pages/Services/Services"),
  loading: Loading,
});

const Agents = Loadable({
  ...loadableProps,
  loader: () =>
    import(/* webpackChunkName: "Agents" */ "@/pages/Agents/Agents"),
  loading: Loading,
});

const Brand = Loadable({
  ...loadableProps,
  loader: () => import(/* webpackChunkName: "Brand" */ "@/pages/Brand/Brand"),
  loading: Loading,
});

const Login = Loadable({
  ...loadableProps,
  loader: () => import(/* webpackChunkName: "Login" */ "@/pages/Login/Login"),
  loading: Loading,
});

function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <Router>
        <Provider store={store}>
          <Switch>
            <Route exact path="/" render={() => <Index />} />
            <Route
              exact
              path="/sample-center"
              render={() => <SampleCenter />}
            />
            <Route exact path="/services" render={() => <Services />} />
            <Route exact path="/agents" render={() => <Agents />} />
            <Route exact path="/brand" render={() => <Brand />} />
            <Route exact path="/login" render={() => <Login />} />
          </Switch>
        </Provider>
      </Router>
    </ConfigProvider>
  );
}

export default App;
